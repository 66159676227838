<template>
  <div>
    <ok-table
      ref="datatable"
      :config="config"
      :title="$t('store.price-labels')"
      @edit="openModal"
      @delete="handleDelete"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="openModal()"
        >
          {{ $t('store.add-another-price-template') }}
        </b-button>
      </template>
      <template
        slot="title-column"
        slot-scope="{props}"
      >
        <b-badge
          variant="primary"
        >
          {{ getTitle(props.row) }}
        </b-badge>

      </template>
    </ok-table>
    <process-label-modal
      v-model="showLabelModal"
      :label="selectedLabel"
      @updated="refreshTable"
    />
  </div>
</template>

<script>
import { BButton, BBadge } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import LocalizationService from '@/services/localization-service'
import PriceLabelsAPIs from '@/api/store/price-label'
import ProcessLabelModal from '@/views/store/price-labels/config/ProcessLabelModal'
import LabelsTableConfig from './config/labels-table-config'

export default {
  components: {
    ProcessLabelModal,
    OkTable,
    BButton,
    BBadge,
  },
  data() {
    return {
      config: LabelsTableConfig,
      showLabelModal: false,
      selectedLabel: {},
      showQuickEditPrice: false,
    }
  },
  methods: {
    getTitle(item) {
      return LocalizationService.getTranslate(item, 'name', this.selectedLocale)
    },
    refreshTable() {
      this.showLabelModal = false
      this.selectedLabel = {}
      this.$refs.datatable.loadItems()
    },
    openModal(data = null) {
      this.showLabelModal = true
      this.selectedLabel = data?.row ?? null
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => PriceLabelsAPIs.delete(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },
}
</script>
