import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import CommonTableActions from '@/constants/common-table-actions'

const LabelsTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/price-labels`,
  rowsPath: 'labels',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'fields.name',
      field: 'title',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    ...CommonTableActions,
  ],
  filters: [
  ],
}

export default LabelsTableConfig
